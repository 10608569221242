/**
 * 自动回复js
 */
import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";
const request = axios.request;
/**
 * 获取自动回复信息
 * @returns {Promise<unknown>}
 */
export const getautoreply = (data) => {
  return request.doPost('/autoreply/getAutoreply', data);
}

/**
 * 添加或者修改自动回复信息
 * @returns {Promise<unknown>}
 */
export const addorupdateautoreply = (data) => {
  return request.doPost('/autoreply/addorupdateAutoreply', data);
}

/**
 * 删除自动回复信息
 * @returns {Promise<unknown>}
 */
export const deleteautoreply = (data) => {
  return request.doPost('/autoreply/deleteAutoreply', data);
}

/**
 * 修改自动回复状态
 * @returns {Promise<unknown>}
 */
export const updateautoreplyStatus = (data) => {
  return request.doPost('/autoreply/updateAutoreplyStatus', data);
}

/**
 * 修改自动回复状态
 * @returns {Promise<unknown>}
 */
export const getMaxSortId = (data) => {
  return request.doPost('/autoreply/getMaxSortId', data);
}
