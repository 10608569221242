<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.keyword" placeholder="关键字" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter"/>
      <el-input v-model="listQuery.projname" placeholder="项目名称" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter"/>
      <el-select v-model="listQuery.status" placeholder="状态" clearable style="width: 90px" class="filter-item">
        <template v-for="status in statusStatus">
          <el-option :label="status.name" :value="status.value"></el-option>
        </template>
      </el-select>
      <el-select v-model="listQuery.quicktype" placeholder="快捷类型" clearable style="width: 150px" class="filter-item">
        <template v-for="quicktype in quickType">
          <el-option :label="quicktype.name" :value="quicktype.value"></el-option>
        </template>
      </el-select>
      <el-select v-model="listQuery.type" placeholder="类型" clearable style="width: 90px" class="filter-item">
        <template v-for="type in typeStatus">
          <el-option :label="type.name" :value="type.value"></el-option>
        </template>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">
        添加
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" sortable="custom" align="center" width="80">
        <template slot-scope="{row}">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目编号" sortable="custom" align="center" width="120">
        <template slot-scope="{row}">
          <span>{{ row.projid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" align="center" width="250px">
        <template slot-scope="{row}">
          <span>{{ row.projname}}</span>
        </template>
      </el-table-column>
      <el-table-column label="快捷类型" width="150px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.quicktype | quicktypeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关键字" width="180px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.keyword }}</span>
        </template>
      </el-table-column>
      <el-table-column label="多关键字" width="180px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.keywords }}</span>
        </template>
      </el-table-column>
      <el-table-column label="回复内容" width="100px">
        <template slot-scope="{row}">
          <a @click="showContent(row)" style="color: #00bb00">查看详情内容</a>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.type | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.status | statusFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="排序" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{row.sortid}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.updatetime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="250" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="{row,$index}">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">
            修改
          </el-button>
          <el-button v-if="row.status!='1'" size="mini" type="success" @click="handleModifyStatus(row,'1')">
            禁用
          </el-button>
          <el-button v-if="row.status!='0'" size="mini" @click="handleModifyStatus(row,'0')">
            解禁
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row,$index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>

    <el-dialog @close="handleClose(temp.replytype)" :title="textMap[dialogaccount_status]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <el-form-item label="选择项目" prop="projid">
          <el-select v-model="temp.projid" placeholder="请选择项目" clearable style="width: 300px">
            <template v-for="pro in projectList">
              <el-option :label="pro.projname" :value="pro.projid"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="快捷类型" prop="quicktypes">
          <el-checkbox-group v-model="temp.quicktypes">
            <el-checkbox :label="1">其他</el-checkbox>
            <el-checkbox :label="2">热门问题</el-checkbox>
            <el-checkbox :label="3">热点标签回复</el-checkbox>
            <el-checkbox :label="4">人工客服问候语</el-checkbox>
            <el-checkbox :label="5">首次连接自动发送</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="关键字" prop="keyword">
          <el-input v-model="temp.keyword" type="text" placeholder="请输入回复关键字"/>
        </el-form-item>
        <el-form-item label="搜索关键字" prop="keywords">
          <el-input v-model="temp.keywords" type="text" placeholder="请输入多个关键字，用【;】隔开"/>
        </el-form-item>
        <el-form-item label="回复类型" prop="type">
          <el-radio-group v-model="temp.replytype">
            <el-radio :label="1">文本</el-radio>
            <el-radio :label="2">关键字套接</el-radio>
            <el-radio :label="4">视频文件</el-radio>
            <el-radio :label="5">图标链接</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="回复文本" v-if="temp.replytype ==1">
          <el-input v-model="temp.content"  type="text" placeholder="请输入回复文本"/>
        </el-form-item>
        <el-form-item label="联系QQ" v-if="temp.replytype ==5">
          <el-input v-model="temp.qq" @input="inputQQ" type="text" placeholder="请输入联系QQ"/>
        </el-form-item>
        <el-form-item v-if="temp.replytype ==5" label="图标链接">
          <div>
            <img @click="clickIcon(1)" class="qqtalk" src="/images/qqtalk.jpg" style="vertical-align:middle;height: 25px"></img>
          </div>
        </el-form-item>
        <el-form-item v-if="temp.replytype !=3 && temp.replytype !=4" label="回复内容">
          <editor @onInit="getEditor" v-model="temp.content"></editor>
        </el-form-item>
        <el-form-item v-if="temp.replytype ==4" label="视频上传" prop="Video">
          <!-- action必选参数, 上传的地址 -->
          <el-upload class="avatar-uploader el-upload--text"
                     :http-request="fileUploadFunction"
                     action=""
                     :show-file-list="false" :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo"
                     :on-progress="uploadVideoProcess">
            <video v-if="videoForm.Video !='' " width="400px" :src="videoForm.Video" ref="vueMiniPlayer" class="video" controls="controls" autoplay>您的浏览器不支持视频播放</video>
            <i v-else-if="videoForm.Video =='' " class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <P class="text">请保证视频格式正确，且不超过10M</P>
        </el-form-item>
        <el-form-item label="排序" prop="sortid">
          <el-input v-model="temp.sortid" type="text" placeholder="请输入排序"/>
        </el-form-item>
        <el-button v-if="temp.replytype ==2" @click="addDomain">添加</el-button>
        <el-form-item v-if="temp.replytype ==2"
                      v-for="(linkupkey, index) in temp.autoreplyLinkupKeyList"
                      :key="linkupkey.key">
          <el-input v-model="linkupkey.clicktext" type="text" placeholder="替换文本" style="width: 200px;"></el-input>
          <el-input v-model="linkupkey.keyword" placeholder="替换关键字" style="width: 200px;margin: 0px 10px"></el-input>
          <el-button @click.prevent="removeDomain(linkupkey)">删除</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogaccount_status==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <el-dialog  @close="handleClose(temp.replytype)" title="回复内容" :visible.sync="dialogContent" width="500px">
      <div v-if="temp.replytype !=4" v-html="content"></div>
      <div v-if="temp.replytype ==4">
        <video width="400px" :src="content" class="video" id="video" ref="vueMiniPlayer" controls="controls" autoplay>您的浏览器不支持视频播放</video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {getautoreply, addorupdateautoreply, deleteautoreply, updateautoreplyStatus, getMaxSortId} from "@/apis/auto-reply";
  import {parseTime} from '@/utils'
  import editor from "@CMP/common/WangEditor";

  console.log(editor)
  const statusOptions = [
    {key: '0', display_name: '正常'},
    {key: '1', display_name: '禁用'},
  ]
  const typeOptions = [
    {key: '1', display_name: '文本'},
    {key: '2', display_name: '关键字套接'},
    {key: '3', display_name: '语音'},
    {key: '4', display_name: '视频文件'},
    {key: '5', display_name: '图标链接'},
  ]
  const QuickType = [
    {key: '1', display_name: '其他'},
    {key: '2', display_name: '热门问题'},
    {key: '3', display_name: '热点标签回复'},
    {key: '4', display_name: '人工客服问候语'},
    {key: '5', display_name: '首次连接自动发送'},
  ]
  const statusTypeKeyValue = statusOptions.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  const TypeKeyValue = typeOptions.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  const QuickTypeKeyValue = QuickType.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  export default {
    name: 'refcsstaffproject',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
      Editor: editor
    },
    filters: {
      statusFilter(type) {
        return statusTypeKeyValue[type]
      },
      typeFilter(type) {
        return TypeKeyValue[type]
      }, quicktypeFilter(quicktype) {
        let Str = '';
        quicktype.split(",").filter(item => {
          if (Str == '') {
            Str = QuickTypeKeyValue[item]
          } else {
            Str += "/" + QuickTypeKeyValue[item]
          }
        })
        return Str;
      },
      parseTime: parseTime
    },
    data() {
      return {
        videoFlag: false,
        videoForm: {
          Video: ""
        },
        initEditor: '',
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
        dialogContent: false,
        content: '2',
        type: '2',
        listQuery: {
          page: 1,
          limit: 20,
          keyword: '',
          projname: '',
          type: '',
          status: '',
          quicktype: '',
          auid: null
        },
        projectList: [],
        showReviewer: false,
        temp: {
          id: null,
          keyword: '',
          keywords: '',
          projid: '',
          content: '',
          replytype: 1,
          quicktypes: [1],
          quicktype: "1",
          sortid: 1,
          audiourl: '',
          auid: null,
          autoreplyLinkupKeyList: [{
            clicktext: '',
            keyword: ''
          }],
        },
        dialogFormVisible: false,
        dialogaccount_status: '',
        textMap: {
          update: '修改',
          create: '添加'
        },
        dialogPvVisible: false,
        pvData: [],
        rules: {
          projid: [{required: true, message: '请选择项目', trigger: 'change'}],
          keyword: [{required: true, message: '请输入关键字', trigger: 'change'}],
          keywords: [{required: true, message: '请输入搜索关键字', trigger: 'change'}],
          content: [{required: true, message: '请输入回复内容', trigger: 'change'}],
          sortid: [{required: true, message: '请输入排序', trigger: 'change'}],
          quicktypes: [{required: true, message: '请选中快捷类型', trigger: 'change'}],
          replytype: [{required: true, message: '请选择回复类型', trigger: 'change'}]
        },
        downloadLoading: false
      }
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.adminuid = this.user.userId
        getautoreply(this.listQuery).then(res => {
          const {count = 0} = res;
          this.list = res.retdata.dataList;
          this.total = count;
          this.projectList = res.retdata.projectList;
          this.listLoading = false;
        })
      }, addDomain() {
        this.temp.autoreplyLinkupKeyList.push({
          value: '',
          key: Date.now()
        });
      }, removeDomain(item) {
        var index = this.temp.autoreplyLinkupKeyList.indexOf(item)
        if (index !== -1) {
          this.temp.autoreplyLinkupKeyList.splice(index, 1)
        }
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      resetTemp() {
        this.temp = {
          id: null,
          keyword: '',
          keywords: '',
          qq: '',
          projid: '',
          content: '',
          replytype: 1,
          quicktypes: [1],
          quicktype: "1",
          sortid: 1,
          audiourl: '',
          auid: null,
          autoreplyLinkupKeyList: [{
            clicktext: '',
            keyword: ''
          }],
        }
      },
      handleCreate() {
        this.resetTemp()
        this.getMaxSortId()
        this.dialogaccount_status = 'create'
        this.dialogFormVisible = true
        this.videoForm.Video = '';
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      createData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.auid = this.user.userId
            if (this.temp.replytype != 4) {
              this.temp.content = this.initEditor.txt.html();
            }
            this.temp.quicktype = this.temp.quicktypes.join(",");
            addorupdateautoreply(this.temp).then(res => {
              console.log(this.temp)
              this.$message.success(res.retDesc);
              this.dialogFormVisible = false;
              this.getList();
            });
          }
        })
      },
      handleUpdate(row) {
        this.temp = {
          id: row.id,
          keyword: row.keyword,
          keywords: row.keywords,
          projid: row.projid,
          content: row.content,
          replytype: row.replytype,
          quicktypes: [],
          quicktype: row.quicktype,
          sortid: row.sortid,
          qq: row.qq,
          audiourl: row.audiourl,
          auid: row.auid,
          autoreplyLinkupKeyList: row.autoreplyLinkupKeyList
        }
        if (row.replytype == 4) {
          this.videoForm.Video = row.content;
        }
        this.temp.quicktypes = [];
        let this_ = this;
        let quicktype = this.temp.quicktype.split(',');
        quicktype.filter(item => {
          this_.temp.quicktypes.push(parseInt(item));
        })
        this.dialogaccount_status = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
        if (this.initEditor != '') {
          this.initEditor.txt.html(this.temp.content)
        }
      }, showContent(row) {
        this.content = row.content;
        this.type = row.replytype;
        this.dialogContent = true;
      },
      updateData() {
        console.log(this.temp)
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            console.log(this.temp)
            this.temp.auid = this.user.userId
            if (this.temp.replytype != 4) {
              this.temp.content = this.initEditor.txt.html();
            }
            this.temp.quicktype = this.temp.quicktypes.join(",");
            addorupdateautoreply(this.temp).then(res => {
              this.$message.success(res.retDesc);
              this.dialogFormVisible = false;
              this.getList();
            });
          }
        })
      },
      formatJson(filterVal) {
        return this.list.map(v => filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }))
      },
      handleModifyStatus(row, status) {
        this.temp = Object.assign({}, row)
        var id = this.temp.id;
        updateautoreplyStatus({id, status}).then(res => {
          this.$message.success(res.retDesc);
          row.status = status
        })
      },
      handleDelete(row, index) {
        this.temp = Object.assign({}, row)
        var id = this.temp.id;
        deleteautoreply({id}).then(res => {
          this.$message.success(res.retDesc);
          this.list.splice(index, 1)
        });
      }, getEditor(editor) {
        this.initEditor = editor;
        this.initEditor.txt.html(this.temp.content)
      }, getMaxSortId() {
        var adminuid = this.user.userId;
        getMaxSortId({adminuid}).then(res => {
          this.temp.sortid = res.retdata + 1;
        });
      }, beforeUploadVideo(file) {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.replytype) == -1) {
          this.$message.error('请上传正确的视频格式');
          return false;
        }
        if (!isLt10M) {
          this.$message.error('上传视频大小不能超过10MB哦!');
          return false;
        }
      }, uploadVideoProcess(event, file, fileList) {
        console.log(event.percent, file, fileList)
        this.videoFlag = true
        this.videoUploadPercent = Math.floor(event.percent)
      }, handleVideoSuccess(res, file) {
        this.videoForm.Video = res.src;
        this.temp.content = res.src;
        this.videoForm.Video = URL.createObjectURL(file.raw);
      },handleClose(type){
        console.log("关闭")
        if (replytype==4)
        this.$refs.vueMiniPlayer.pause();
      }, clickIcon(replytype) {
        if (!this.temp.qq) {
          this.$message.error("请先输入联系QQ");
          return false;
        }
        let content = this.initEditor.txt.html();
        if (replytype == 1) {
          content = content + '<a href="https://wpa.qq.com/msgrd?v=3&uin=' + this.temp.qq + '&site=qq&menu=yes" target="_blank"><img @click="qqtalk(1)" src="/images/qqtalk.jpg" style="vertical-align:middle;height: 25px"></img></a>';
        }
        if (this.initEditor != '') {
          this.initEditor.txt.html(content)
        }
      }, inputQQ() {
        if (this.initEditor != '') {
          this.initEditor.txt.html('')
        }
      }
    }, computed: {
      statusStatus() {
        return [
          {name: '全部', value: null},
          {name: '禁用', value: 1},
          {name: '正常', value: 0}
        ]
      },
      typeStatus() {
        return [
          {name: '全部', value: null},
          {name: '文本', value: 1},
          {name: '关键字套接', value: 2},
          {name: '语音', value: 3},
          {name: '视频文件', value: 4},
          {name: '图标链接', value: 5},
        ]
      },
      quickType() {
        return [
          {name: '全部', value: null},
          {name: '其他', value: 1},
          {name: '热门问题', value: 2},
          {name: '热点标签回复', value: 3},
          {name: '人工客服问候语', value: 4},
          {name: '首次连接自动发送', value: 5},
        ]
      },
    }
  }
</script>

<style scoped>
  .filter-item {
    margin-left: 5px;
  }

</style>
